import React from 'react';
import styles from './Profile.module.scss';
import { Avatar, Button, Card, Col, Divider, List, Row } from "antd";
import Image from "../../images/nik.jpg";
import { darkBlue, Paragraph } from "../../StyledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngular, faAws, faDocker, faJira, faLinkedin, faNodeJs, faReact } from "@fortawesome/free-brands-svg-icons";
import { faAt, faDatabase, faGraduationCap } from "@fortawesome/pro-duotone-svg-icons";
import { faCode } from "@fortawesome/pro-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// @ts-ignore
export const faCircleCi = {
  prefix: 'fab',
  iconName: 'circleci',
  icon: [
    104,
    106,
    [],
    "f0000",
    "m 38.6,52.6 c 0,-6.9 5.6,-12.5 12.5,-12.5 6.9,0 12.5,5.6 12.5,12.5 0,6.9 -5.6,12.5 -12.5,12.5 C 44.2,65.2 38.6,59.5 38.6,52.6 Z M 51.1,0 C 26.5,0 5.9,16.8 0.1,39.6 0.1,39.8 0,39.9 0,40.1 c 0,1.4 1.1,2.5 2.5,2.5 l 21.2,0 c 1,0 1.9,-0.6 2.3,-1.5 l 0,0 C 30.4,31.6 39.9,25 51.1,25 66.3,25 78.7,37.4 78.7,52.6 78.7,67.8 66.3,80.2 51.1,80.2 40,80.2 30.4,73.6 26,64.1 l 0,0 c -0.4,-0.9 -1.3,-1.5 -2.3,-1.5 l -21.2,0 c -1.4,0 -2.5,1.1 -2.5,2.5 0,0.2 0,0.3 0.1,0.5 5.8,22.8 26.4,39.6 51,39.6 29.1,0 52.7,-23.6 52.7,-52.7 C 103.8,23.5 80.2,0 51.1,0 Z"
  ]
} as IconDefinition;

const skillData = [{
  icon: faReact,
  description: 'React'
}, {
  icon: faAngular,
  description: 'Angular'
}, {
  icon: faNodeJs,
  description: 'NodeJS'
}, {
  icon: faAws,
  description: 'AWS'
}, {
  icon: faDocker,
  description: 'Docker'
}, {
  icon: faDatabase,
  description: 'Postgres'
}, {
  icon: faCircleCi,
  description: 'CircleCI'
}, {
  icon: faCode,
  description: 'Integrations'
}, {
  icon: faJira,
  description: 'JIRA'
}];

const Profile = (props: any) => {
return (
  <div>
    <Card bordered={false}>
      <div className={styles.NameDivider}>
        <div>
          <Row justify="center">
            <Avatar size={128} src={Image}/>
          </Row>
          <Divider className={styles.Divider} orientation="center">
            Nikolaus Wüst
            <br/>
            <div style={{fontWeight: 300, fontSize: '11px'}}>Full Stack Software Engineer</div>
          </Divider>
          <Paragraph align="center" fontSize={1.2}></Paragraph>
          <List
            className="FullWidth"
            size="small"
            dataSource={skillData}
            renderItem={item => (
              <List.Item>
                <div className="FullWidth RowSpacebetween">
                  <div style={{width: '30px', display: 'flex', justifyContent: 'center'}}>
                    <FontAwesomeIcon icon={item.icon} color={darkBlue} size="lg"/>
                  </div>
                  <div style={{fontWeight: 600}}>
                    {item.description}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Card>
    <Row className="ButtonContainer RaisedButtons" gutter={[8, 8]}>
      <Col span={24}>
        <a className="FullWidth"
           href="https://www.linkedin.com/in/nikolaus-wust-961769b4/"
           target="_blank" rel="noopener noreferrer">
          <Button block className="RaisedButton">
            <div className='RowSpacebetween'>
              <div>
                <FontAwesomeIcon icon={faLinkedin} color={darkBlue} className="LeftIcon" size="lg"/>
              </div>
              <div>LinkedIn</div>
            </div>
          </Button>
        </a>
      </Col>
      <Col span={24}>
        <a className="FullWidth"
           href="mailto:hello@nikolauswust.com"
           target="_blank" rel="noopener noreferrer">
          <Button block className="RaisedButton">
            <div className='RowSpacebetween'>
              <div>
                <FontAwesomeIcon icon={faAt} color={darkBlue} className="LeftIcon" size="lg"/>
              </div>
              <div>Email</div>
            </div>
          </Button>
        </a>
      </Col>
    </Row>
  </div>
);
};

Profile.defaultProps = {

};

Profile.propTypes = {

};

export default Profile;