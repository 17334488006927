import styled from "styled-components";

export const rose = '#B26B7A';
export const cyan = '#45D8FB';
export const lightBlue = '#9EAEDF';
export const blue = '#004ABC';
export const darkBlue = '#001C3C';
export const gunmetal = '#292f36';

export const Title =
  styled.h1<{ fontSize?: number, align?: 'left' | 'center' | 'right' }>
  (props => ({
    'fontSize': `${props.fontSize}em`,
    'textAlign': props.align,
    'color': gunmetal,
    'whiteSpace': 'pre-line',
  }));

Title.defaultProps = {
  fontSize: 1.5,
  align: 'left'
}

export const Paragraph =
  styled.p<{ fontSize?: number, align?: 'left' | 'center' | 'right' }>
  (props => ({
    'marginTop': '0.8em',
    'marginBottom': '0.8em',
    'fontSize': `${props.fontSize}em`,
    'textAlign': props.align,
    'color': gunmetal,
    'whiteSpace': 'pre-line',
  }));

Paragraph.defaultProps = {
  fontSize: 1,
  align: 'left'
}

/*export const Button = styled.button`
  text-align: center;
  color: ${gunmetal};
  border: 2px solid ${blue};
  :hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`;*/

function StyledComponents() {
}

export default StyledComponents;
