import React from 'react';
import styles from './MainContent.module.scss';
import { darkBlue, Paragraph, Title } from '../../StyledComponents';
import { Button, Row, Col, Divider, List, Drawer, Card, Grid } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faGraduationCap,
  faTerminal,
  faUserCircle
} from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

// @ts-ignore
export const faAkora = {
  prefix: 'fab',
  iconName: 'akora',
  icon: [
    188,
    184,
    [],
    "f0000",
    "M75.53,114.97L93.14 72.91 82.1 46.56 44.48 136.38 z M145.6,140.02L123.53 140.02 114.58 118.62 110.37 108.55 101.84 88.16 94.3 70.16 83.29 43.89 83.28 43.87 105.36 43.87 117.94 73.94 126.52 94.38 z M111.07,116.2L74.67 140.02 42.95 140.02 94.32 104.59 z M112.05,118.09L96.22 128.46 113.89 140.02 121.22 140.02 z"
  ]
} as IconDefinition;

const experienceData = [{
  icon: faAkora,
  title: 'Akora — Technical Lead and Co-founder',
  blurb: 'Akora is a licensed real estate agency with our own propriety software that ' +
    'enables us to operate at much greater efficiency and autonomy than any ' +
    'other agent in the market. Every aspect of managing a property has been ' +
    'modernised, with our team focusing on how technology can improve the ' +
    'daily life of a property manager, allowing them to give a much better level ' +
    'of service to their clients. In my role I was directly responsible for leading ' +
    'a team of 3 software engineers to design and build the Akora property ' +
    'management platform.',
  list: [
    'Architect and build modules to fulfil business requirements.',
    'Interview end users and derive requirements in order to plan and build a valuable product.',
    'Facilitate and plan bi-weekly sprints on JIRA.',
    'Build and expand Akora\'s relational SQL database schema to suit product requirements.',
    'Develop and automate Akora\'s DevOps architecture using CircleCI and AWS products such as EC2, RDS, and S3.',
    'Build, implement and maintain unit, integration and acceptance tests.',
    'Triage and prioritise bugs, and perform postmortems as required.',
    'Support all end users of the Akora platform such as owners, tenants and property managers.'
  ]
}, {
  title: 'HSS — Information Management Support Officer',
  blurb: 'I worked on a fixed contract for Health Support Services (as part of the ' +
    'overall Perth Children Hospital project) on a greenfield project to onboard ' +
    'every employee onto HP Service Manager, our new request and change ' +
    'management software. In my role I was part of a team that was responsible ' +
    'for the administration, management and enhancement for HPSM.',
  list: [
    'Administrate ITSM software package HPSM for over 40,000 Department of Health employees.',
    'Build, test and deploy scripts and bug fixes using the ITIL Change Management process from Developer and User Acceptance Testing environments to Production.',
    'Build and test reports in HPSM and SSRS to be used by Health employees in Production environment.',
    'Manage and delegate incoming incidents and requests in the Service Management team’s job queue.'
  ]
}];

const educationData = [{
  title: 'Bachelor of Science (Software Engineering)',
  blurb: 'Curtin University of Technology, Perth',
  dateRange: 'February 2014 — December 2018'
}];

const accomplishmentData = [{
  title: 'Akora — Building a business and bringing a product to market',
  list: [
    'The Akora property management platform was launched for operation in January 2020 to several customers and has been growing steadily since. Our customers are elated because they are saving thousands on management fees and are also receiving a better service.',
    'We won a grant from Landgate, the governing authority responsible for property data in Western Australia. The grant was for $17,500 to be used to develop software using Landgate\'s extensive data set to automate our landlord approval process.',
    'Akora was accepted into the PlusEight accelerator program, where our initial application was accepted from over 70 other startups in Perth. ' +
    'Throughout the program, we learned a lot from some amazing mentors which helped us take our business to the next level. ' +
    'We pitched our way to the final stage in the program where we received $50,000 in funding as well as further learning opportunities and industry connections.'
  ]
}];

const aboutData = [{
  blurb: 'As well as being a startup founder, I\'m a father to an amazing and clever toddler (maybe too clever...). Being a father has taught me a lot about myself and what I want out of life. ' +
    'In mid 2017, I got in contact with Barkley - an old high school classmate and now cofounder at Akora - where we started working on the idea full time. Little did we know how hard it would be, ' +
    'it was essentially like being thrown in the deep end and forced to swim. I learned everything there was to starting and scaling a technology business, and am so glad I took the plunge.',
  interests: [
    'I find it incredibly rewarding to cook up delicious meals for my family and friends',
    'Formula 1, the engineering aspect of it completely amazes me',
    'Really, really good coffee',
    'I love trying new and unique craft beers from around our great state'
  ]
}];

class MainContent extends React.Component {
  state = {
    visible: null
  };

  showDrawer = (drawer: 'experience' | 'about' | 'education' | 'accomplishments') => {
    if (this.state.visible === drawer) {
      this.onClose();
    } else {
      this.setState({
        visible: drawer
      });
    }
  };

  onClose = () => {
    this.setState({
      visible: null
    });
  };

  render() {
    return (
      <div className={styles.ContentContainer}>
        <Card className={styles.ContentCard} bordered={false}
              style={{backgroundSize: '100% calc(100% + 185px) !important', paddingBottom: '40px !important'}}>
          <div className={this.state.visible !== null ? 'Blur FullWidth' : 'FullWidth'}>
            <Title align="left">Hi there!</Title>
            <Paragraph align="left">
              Welcome to my website.
              <br/>
              Check out some of the links below or read on to see what I'm all about.
            </Paragraph>
            <Divider/>
            <Paragraph>
              I'm an experienced and driven full stack software engineer, as well as startup founder, with the proven
              ability to architect, execute, and release a well-designed product from the ground up.
            </Paragraph>
            <Paragraph>
              Throughout my career I have demonstrated strong technical abilities, as well as project planning and
              problem solving skills.
            </Paragraph>
            <Paragraph>
              I have the ability to resourcefully budget my time and use my technical aptitude to get the job done;
              I am also self-aware enough to know my limits.
            </Paragraph>
            <Divider/>
            <Paragraph>
              I'm currently looking for contract or permanent employment, please don't hesitate to email me directly
              if you think I can help with your project. Whether it's big or small, I'm sure I can add a lot of value.
            </Paragraph>
          </div>
          <Drawer title={
            <Row align="middle">
              <FontAwesomeIcon icon={faTerminal} swapOpacity color={darkBlue} className="LeftIcon"/>
              <b>Experience</b>
            </Row>
          }
                  placement="bottom"
                  closable={true}
                  closeIcon={<FontAwesomeIcon icon={faTimes}/>}
                  height='80%'
                  onClose={this.onClose}
                  visible={this.state.visible === 'experience'}
                  getContainer={false}
                  bodyStyle={{padding: 0}}
                  style={{position: 'absolute'}}>
            <div>
              <List
                className="FullWidth"
                size="small"
                dataSource={experienceData}
                renderItem={item => (
                  <List.Item>
                    <div>
                      <Divider orientation="center">
                        <div className="RowSpacebetween">
                          {item.icon ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <FontAwesomeIcon className="LeftIcon" icon={item.icon} color={darkBlue} size="2x"/>
                            </div> : ''}
                          <div style={{fontWeight: 600, lineHeight: '32px'}}>
                            {item.title}
                          </div>
                        </div>
                      </Divider>
                      <Title fontSize={1.2}>About</Title>
                      <Paragraph align='left'>
                        {item.blurb}
                      </Paragraph>
                      <Title fontSize={1.2}>Responsibilities</Title>
                      <List size="small"
                            dataSource={item.list}
                            renderItem={item => (
                              <List.Item>
                                {item}
                              </List.Item>
                            )}>
                      </List>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Drawer>
          <Drawer title={
            <Row align="middle">
              <FontAwesomeIcon icon={faGraduationCap} swapOpacity color={darkBlue} className="LeftIcon"/>
              <b>Education</b>
            </Row>
          }
                  placement="bottom"
                  closable={true}
                  closeIcon={<FontAwesomeIcon icon={faTimes}/>}
                  height='80%'
                  onClose={this.onClose}
                  visible={this.state.visible === 'education'}
                  getContainer={false}
                  bodyStyle={{padding: 0}}
                  style={{position: 'absolute'}}>
            <div>
              <List
                className="FullWidth"
                size="small"
                dataSource={educationData}
                renderItem={item => (
                  <List.Item>
                    <div className="FullWidth">
                      <Divider orientation="center">
                        <div style={{fontWeight: 600}}>
                          {item.title}
                        </div>
                      </Divider>
                      <Paragraph align='left'>
                        {item.blurb}
                        <br/>
                        <span style={{fontWeight: 'lighter', fontSize: '0.85em'}}>
                          {item.dateRange}
                        </span>
                      </Paragraph>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Drawer>
          <Drawer title={
            <Row align="middle">
              <FontAwesomeIcon icon={faAward} swapOpacity color={darkBlue} className="LeftIcon"/>
              <b>Accomplishments</b>
            </Row>
          }
                  placement="bottom"
                  closable={true}
                  closeIcon={<FontAwesomeIcon icon={faTimes}/>}
                  height='80%'
                  onClose={this.onClose}
                  visible={this.state.visible === 'accomplishments'}
                  getContainer={false}
                  bodyStyle={{padding: 0}}
                  style={{position: 'absolute'}}>
            <div>
              <List
                className="FullWidth"
                size="small"
                dataSource={accomplishmentData}
                renderItem={item => (
                  <List.Item>
                    <div>
                      <Divider orientation="center">
                        <div style={{fontWeight: 600, lineHeight: '32px'}}>
                          {item.title}
                        </div>
                      </Divider>
                      <List size="small"
                            dataSource={item.list}
                            renderItem={item => (
                              <List.Item>
                                {item}
                              </List.Item>
                            )}>
                      </List>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Drawer>
          <Drawer title={
            <Row align="middle">
              <FontAwesomeIcon icon={faUserCircle} swapOpacity color={darkBlue} className="LeftIcon"/>
              <b>About Me</b>
            </Row>
          }
                  placement="bottom"
                  closable={true}
                  closeIcon={<FontAwesomeIcon icon={faTimes}/>}
                  height='80%'
                  onClose={this.onClose}
                  visible={this.state.visible === 'about'}
                  getContainer={false}
                  style={{position: 'absolute'}}>
            <div>
              <List
                className="FullWidth"
                size="small"
                dataSource={aboutData}
                renderItem={item => (
                  <div>
                    <Paragraph>
                      {item.blurb}
                    </Paragraph>
                    <Title fontSize={1.2}>
                      My Interests
                    </Title>
                    <List size="small"
                          dataSource={item.interests}
                          renderItem={item => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}>
                    </List>
                  </div>
                )}
              />
            </div>
          </Drawer>
        </Card>
        <div>
          <Row className="ButtonContainer RaisedButtons" gutter={[8, 8]}>
            <Col span={12}>
              <Button block onClick={() => this.showDrawer('experience')}>
                <div className='RowSpacebetween'>
                  <div>
                    <FontAwesomeIcon icon={faTerminal} swapOpacity color={darkBlue} className="LeftIcon" size="lg"/>
                  </div>
                  <div>Experience</div>
                </div>
              </Button>
            </Col>
            <Col span={12}>
              <Button block onClick={() => this.showDrawer('education')}>
                <div className='RowSpacebetween'>
                  <div>
                    <FontAwesomeIcon icon={faGraduationCap} swapOpacity color={darkBlue} className="LeftIcon"
                                     size="lg"/>
                  </div>
                  <div>Education</div>
                </div>
              </Button>
            </Col>
            <Col span={12}>
              <Button block onClick={() => this.showDrawer('accomplishments')}>
                <div className='RowSpacebetween'>
                  <div>
                    <FontAwesomeIcon icon={faAward} color={darkBlue} className="LeftIcon" size="lg"/>
                  </div>
                  <div>Accomplishments</div>
                </div>
              </Button>
            </Col>
            <Col span={12}>
              <Button block onClick={() => this.showDrawer('about')}>
                <div className='RowSpacebetween'>
                  <div>
                    <FontAwesomeIcon icon={faUserCircle} color={darkBlue} className="LeftIcon" size="lg"/>
                  </div>
                  <div>About Me</div>
                </div>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default MainContent;