import React from 'react';
import './App.scss';
import { Col, Row, Grid } from "antd";
import MainContent from "./components/MainContent";
import Profile from "./components/Profile";

const {useBreakpoint} = Grid;

const App = () => {
  const screens = useBreakpoint();
  const entries = Object.entries(screens).filter(screen => !!screen[1] && (screen[0] === 'xs'));
  const small = entries.length > 0;
  return (
    <div className={small ? 'App Small' : 'App'}>
      <Row className="MainContainer" align="stretch" gutter={[16, 16]}>
        <Col span={small ? 24 : 8}>
          <Profile/>
        </Col>
        <Col span={small ? 24 : 16}>
          <MainContent/>
        </Col>
      </Row>
    </div>
  );
}

export default App;
